.custom-btn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
}
.custom-btn:hover {
  background-color: rgba(128, 128, 128, 0.373);
}
.status-btn {
  padding: 2px;
  margin: auto;
  width: 50px;
  height: 35px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}
