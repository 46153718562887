.search-row {
  align-items: center;
  justify-content: flex-start;
}

.search-form .ant-form-item {
  margin-bottom: 0;
}

.ant-input-group-addon {
  visibility: hidden;
}

.input-search .ant-input {
  height: 30px;
  font-weight: 300;
}

.input-search .ant-input-affix-wrapper {
  border-radius: 6px !important;
  height: 40px;
}
